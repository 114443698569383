import React, { useContext, useState } from "react";
import { LocaleContext } from "../context/context";
import { createTheme, ThemeProvider, styled, createStyles } from "@mui/material/styles";
import { ThemeContext } from "../context/themeContext";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface ErrorProps {
    title: string
}

const Error = ({ title } : ErrorProps) => {
	const context: any = useContext(LocaleContext);
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);
	
    interface themeProps{
        className?: string
    }

    const ErrorComponent = (props: themeProps) : JSX.Element =>{
    	return (
    		<>
    			<Header/>
    			<div className={"page-container " + props.className} >
    				<div className="page-header">
    					<div className='error-container'> 
    						<Typography className="error-title">{context.locale && title ? title : context.locale?.errorOccurred || process.env.REACT_APP_ERROR_OCCURRED}</Typography>
    						<span className='error-text'>{ context.locale?.errorOccurredDescription || process.env.REACT_APP_ERROR_OCCURRED_DESCRIPTION }</span>
    					</div>
    				</div>
    			</div>
    			<Footer/>
    		</>
    	);
    };

    const ThemeComponent = styled(ErrorComponent)(({theme}) => {
    	return{
    		background: theme.color.lightGrey,
    		"& .error-container": {
    			"display": "flex",
    			"flexDirection": "column",
    			"padding": 10,
    			"@media (min-width: 968px)": {
    				"padding": "unset"
    			}
    		},
    		"& .error-title": {
    			"flex": 1,
    			"font": "normal normal normal 30px/38px Noto Sans Bold",
    			"@media (min-width: 968px)": {
    				"font": "normal normal normal 50px/68px Noto Sans Bold"
    			}
    		},
    		"&. error-text": {
    			"flex": 1
    		}
    	};
    });

    return(
    	<ThemeProvider theme={theme}>
    		<ThemeComponent  />
    	</ThemeProvider>
    );
};

export default Error;