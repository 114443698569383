import "../styles/form.scss";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";
import api from "../api/api";
import {zxcvbn, zxcvbnOptions} from "@zxcvbn-ts/core";
import {LocaleContext} from "../context/context";
import {ThemeContext} from "../context/themeContext";
import {createTheme} from "@mui/material/styles";
import {useContext, useState, useEffect} from "react";
import {useSearchParams, useLocation, Link} from "react-router-dom";

const options = {
	translations: zxcvbnEnPackage.translations,
	graphs: zxcvbnCommonPackage.adjacencyGraphs,
	dictionary: {
		...zxcvbnCommonPackage.dictionary,
		...zxcvbnEnPackage.dictionary
	}
};
zxcvbnOptions.setOptions(options);

const ResetPasswordForm = (props: any): JSX.Element => {
	const context: any = useContext(LocaleContext);
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme as CustomTheme);
	const {search} = useLocation();
	const [clientId, setClientId] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const [formError, setFormError] = useState("");
	const [searchParams] = useSearchParams();

	// State to manage the password, validation status, and strength
	const [password, setPassword] = useState<string>("");
	const [isValid, setIsValid] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [strength, setStrength] = useState<"Weak" | "Medium" | "Strong">("Weak");
	const [strengthScore, setStrengthScore] = useState<number>(0);
	
	// Regex for password validation
	const passwordRegex = /^(?!.*(.)\1{2})([!""#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~\w]{14,50})$/;
	
	// Function to validate the password
	const validatePassword = (input: string): void => {
	if (passwordRegex.test(input)) {
		setIsValid(true);
		setErrorMessage("");
	} else {
		setIsValid(false);
		if (input.length < 14) {
		setErrorMessage("Password must be at least 14 characters long.");
		} else if (input.length > 50) {
		setErrorMessage("Password must be no more than 50 characters long.");
		} else if (/(.)\1{2}/.test(input)) {
		setErrorMessage("Password cannot contain three or more repeated characters.");
		} else {
		setErrorMessage("Password contains invalid characters.");
		}
	}
	};

	// Function to calculate password strength
	const calculateStrength = (input: string): void => {
	let score = 0;

	// Length check
	if (input.length >= 14) score += 1;
	if (input.length >= 20) score += 1;

	// Contains special characters
	if (/[!""#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]/.test(input)) score += 1;

	// Contains numbers
	if (/\d/.test(input)) score += 1;

	// Contains uppercase and lowercase letters
	if (/[A-Z]/.test(input) && /[a-z]/.test(input)) score += 1;

	// Update strength score
	setStrengthScore(score);

	// Determine strength level
	if (score >= 4) {
		setStrength("Strong");
	} else if (score >= 2) {
		setStrength("Medium");
	} else {
		setStrength("Weak");
	}
	};

	// Handle input change
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
	const input = e.target.value;
	setPassword(input);
	validatePassword(input);
	calculateStrength(input);
	};


	const onFormSubmit = (e: any) => {
		try {
			e.preventDefault();
			setSubmitting(true);
			setFormError("");
			props.setFormSuccess("");
			setClientId(searchParams.get("clientId") || "");

			api.ResetPassword(
				{
					password: password,
					brandCode: theme.brand,
					passwordVerification: passwordConfirm,
					token: searchParams.get("token"),
					clientId: searchParams.get("clientId")
				},
				null
			).then((response) => {
				if (response.responseCode === 200) {
					props.setFormSuccess(context.locale[response.message]);
				} else {
					let message = response.details.find((x: any) => x !== undefined).message;
					setFormError(context.locale[message]);
				}
			});
		} catch (err: any) {
			console.error(err);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<>
			<style>
				{`
					input:focus{
						outline: none;
						border: 1.5px solid ${theme.color.primary};
					  }
					fontFamily: "Arial, sans-serif"
        		`}
			</style>
			{!props.formSuccess ?
				<form onSubmit={onFormSubmit} className="form">
					<Box className="form-container">
						<Box className="form-box">
							<label>{context.locale?.password}</label>
							<input
								type="password"
								id="password"
								aria-describedby="password-error"
								className="form-text"
								value={password}
								onChange={handleInputChange}
							/>
							{password !== "" && 
							<PasswordStrengthIndicator
								strength={strength}
								strengthScore={strengthScore}
								errorMessage={errorMessage}
								inputId="password"
							/>}
						</Box>
						<Box className="form-box">
							<label>{context.locale?.passwordConfirm}</label>
							<input
								type="password"
								className="form-text"
								value={passwordConfirm}
								onInput={(e: any) => setPasswordConfirm(e.target.value)}
							/>
						</Box>
					</Box>
					<Box className="form-box">
						{Boolean(formError) &&
                            <Box className="form-error">
                            	{formError}
                            </Box>
						}
					</Box>
					<Box className="form-box row">
						<Button
							type="submit"
							className="form-primary"
							disabled={submitting}
							style={{backgroundColor: theme.color.primaryButton}}
						>
							{props.isNewAccount ? context.locale?.setPassword : context.locale?.resetPassword}
						</Button>
					</Box>
				</form>
				:
				<form className="form">
					<Box className="form-box">
						<Box className="form-body">
							{props.formSuccess}
						</Box>
						<Box className="form-box row">
							<Button component={Link} className="form-primary btn"
								style={{backgroundColor: theme.color.primaryButton, color: "white"}}
								to={`/${search}`}
							>{context.locale?.returnToLogin}</Button>
						</Box>
					</Box>
				</form>
			}
		</>
	);
};

export default ResetPasswordForm;