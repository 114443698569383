import { useContext, useState } from "react";
import { ThemeContext } from "../context/themeContext";
import { createTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import api from "../api/api";
import "../styles/paper.scss";

const ValidateRequest = ({ setPageTitle, component, errorComponent }: any) => {
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);
	const [searchParams] = useSearchParams();
	const [page, setPage] = useState<any>();

	let SearchFields = { token: searchParams.get("clientId") };

	if (searchParams.get("clientId")) {
		api.ValidateClientId(SearchFields).then(response => {
			if (response.responseCode == 200) {
				setPageTitle(response.message);
				setPage(component);
			} else {
				setPage(errorComponent);
			}
		});
	} else {
		window.location.replace(theme.invalidClientIdRedirectUrl);
	}

	return (
		<div>
			{page}
		</div>
	);
};

export default ValidateRequest;