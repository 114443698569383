import React from "react";

interface PasswordStrengthIndicatorProps {
  strength: "Weak" | "Medium" | "Strong";
  strengthScore: number;
  errorMessage: string;
  inputId: string; // ID of the input field for aria-describedby
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({
  strength,
  strengthScore,
  errorMessage,
  inputId,
}) => {
  // Function to get strength color based on level
  const getStrengthColor = (): string => {
    switch (strength) {
      case "Weak":
        return "#ff4d4d"; // Red
      case "Medium":
        return "#ffa64d"; // Orange
      case "Strong":
        return "#4dff4d"; // Green
      default:
        return "#000"; // Black
    }
  };

  // Calculate progress bar width based on strength score
  const getProgressBarWidth = (): string => {
    const maxScore = 5; // Maximum possible score
    return `${(strengthScore / maxScore) * 100}%`;
  };

  return (
    <div style={{ marginBottom: "16px" }}>
      {/* Progress Bar */}
      <div
        role="progressbar"
        aria-valuenow={strengthScore}
        aria-valuemin={0}
        aria-valuemax={5}
        aria-valuetext={strength}
        style={{
          width: "100%",
          height: "24px", // Increased height to accommodate text
          backgroundColor: "#f0f0f0",
          borderRadius: "4px",
          overflow: "hidden",
          position: "relative", // Needed for absolute positioning of text
        }}
      >
        <div
          style={{
            width: getProgressBarWidth(),
            height: "100%",
            backgroundColor: getStrengthColor(),
            borderRadius: "4px",
            transition: "width 0.3s ease, background-color 0.3s ease",
          }}
        />
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#000", // Text color
            fontWeight: "bold",
            fontSize: "12px",
          }}
        >
          {strength}
        </span>
      </div>

      {/* Error Message */}
      {errorMessage && (
        <p
          id={`${inputId}-error`} // Unique ID for the error message
          aria-live="assertive" // Screen reader will announce this immediately
          style={{ color: "#ff4d4d", marginTop: "8px", fontStyle: "italic", fontSize: "10px" }}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default PasswordStrengthIndicator;