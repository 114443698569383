import React, { useContext, useState, useEffect } from "react";
import { LocaleContext } from "../context/context";
import { ThemeContext } from "../context/themeContext";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import "../styles/paper.scss";
import { NavbarBreadCrumb } from "../components/controls/navbarBreadCrumb";
import { getAppTerm } from "./Login";

interface themeProps{
    className?: string
}
interface ForgotPasswordProps{
    title?: string
}

const ForgotPassword = ({title}: ForgotPasswordProps) => {
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);
	const context: any = useContext(LocaleContext);
	const [formSuccess, setFormSuccess] = useState("");

	const PageComponent = (themeProps: themeProps) : JSX.Element => {
		return (
			<div style={{backgroundColor: theme.color.pageBackground}}>
				<Header/>
				<NavbarBreadCrumb breadcrumbText={title} currentPath={[{text: context.locale?.forgotPasswordTitle, link: "/ForgotPassword"}]}/>
				<Box className="paper">
					<Box className="paper-title">
						{!formSuccess ? title ? `${getAppTerm(title, context)} ${context.locale?.forgotPasswordTitle}` : context.locale?.forgotPasswordTitle
							: context.locale?.emailSent}
					</Box >
					<Box className="paper-body">
						<ForgotPasswordForm formSuccess={formSuccess} setFormSuccess={setFormSuccess}/>
					</Box>
				</Box >
				<Footer/>
			</div>
		);
	};

	/**
     * Apply theming to PageComponent
     */
	const ThemeComponent = styled(PageComponent)(({theme}) => {
		let color = theme.color.lightGrey;
		
		return{
			background: color
		};
	});
	
	return(
		<ThemeProvider theme={theme}>
			<ThemeComponent  />
		</ThemeProvider>
	);
};

export default ForgotPassword;