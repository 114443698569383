import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../context/themeContext";
import { createTheme } from "@mui/material/styles";
import "../styles/navbar.scss";
import Logo from "./Logo";

const Navbar = (props: any): JSX.Element => {
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);
	const [headerBackground, setHeaderBackground] = useState("white");

	useEffect(() => {
		window.addEventListener("resize", resize);

		return () => {
			window.removeEventListener("resize", resize);
		};
	}, []);

	const resize = () => {
		if (window !== undefined) {
			let windowWidth = window.innerWidth;
			windowWidth < 968 ? setHeaderBackground(theme.color.headerBackground) : setHeaderBackground("white");
		}
	};

	return (
		<nav className="navbar-items" style={{backgroundColor: headerBackground}}>
			<div className={"navbar " + props.className}>
				<Logo/>
			</div >
		</nav >
	);
};

export default Navbar;