import { Route, Routes, Navigate } from "react-router-dom";
import Error from "../pages/Error";
import { useState, useEffect } from "react";
import { LocaleContext } from "../context/context";
import { ThemeContext } from "../context/themeContext";
import ThemeSchema from "../theme/theme.json";
import Lion from "../scripts/SAL.Lion";
import Theme from "../scripts/SAL.Theme";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Login from "../pages/Login";
import NewAccount from "../pages/NewAccount";
import ValidateRequest from "../components/ValidateRequest";
import { loadRecaptchaSettings } from "../scripts/greRecaptcha";
import { setCookie } from "../scripts/cookie";
import { NewAccountDialog } from "./NewAccountDialog";

function FallbackComponent({ error }: FallbackProps) {
	return (
		<Error title={error.message} />
	);
}

const AppRoutes = (): JSX.Element => {
	Theme.init();
	Theme.addThemeStore(ThemeSchema.theme);
	Lion.init({ defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE || "", currentLocale: process.env.REACT_APP_DEFAULT_LOCALE || "" });

	const [locale, setLocale] = useState(Lion.getTermStore());
	const [theme, setTheme] = useState(Theme.getThemeStore());
	const [pageTitle, setPageTitle] = useState("");
	const [contentInitialized, setContentInitialized] = useState(false);
	
	useEffect(() => {
		async function getLocale() {
			let locale = await Lion.getTermStore();
			setLocale(locale);
			setContentInitialized(true);
		}
		
		const changeIcon = () => {
			const iconElement = document.getElementById("favicon") as HTMLAnchorElement;
			iconElement.href = theme.logo.path;
		};

		document.title = `${window.Theme.setting.name} - Login`;
		getLocale();
		changeIcon();
		loadRecaptchaSettings();
		loadInitialCookies();
	}, []);

	const loadInitialCookies = () => {   
		if (!document.cookie.split(";").some((item) => item.trim().startsWith("isFirstLogin="))) {
			createCookies();
		}  
	};
	
	const createCookies = () => {
		//Create cookie for initial
		setCookie("isFirstLogin", "true", 9999);
	};

	if(contentInitialized) {
		return (
			<LocaleContext.Provider value={{ locale, setLocale }}>
				<ThemeContext.Provider value={{ theme, setTheme }}>
					<ErrorBoundary
						FallbackComponent={FallbackComponent}
						onError={(error, errorInfo) => {}}
					>
						<Routes>
							<Route path={"*"} element={<Error title="" />} />
							<Route path={"/"} element={<ValidateRequest setPageTitle={setPageTitle} errorComponent={<Error title="" />} component={<Login title={pageTitle}/>}/>} />
							<Route path={"/ForgotPassword"} element={<ValidateRequest setPageTitle={setPageTitle} errorComponent={<Error title="" />} component={<ForgotPassword title={pageTitle}/>}/>} />
							<Route path={"/ResetPassword"} element={<ValidateRequest setPageTitle={setPageTitle} errorComponent={<Error title="" />} component={<ResetPassword title={pageTitle}/>}/>} />
							<Route path={"/NewAccount"} element={<ValidateRequest setPageTitle={setPageTitle} errorComponent={<Error title="" />} component={<NewAccount title={pageTitle}/>}/>} />
						</Routes>
						<NewAccountDialog/>
					</ErrorBoundary>
				</ThemeContext.Provider>
			</LocaleContext.Provider>
		);
	}
};
export default AppRoutes;