import { useContext } from "react";
import { ThemeContext } from "../context/themeContext";
import { useSearchParams } from "react-router-dom";
import { LocaleContext } from "../context/context";
import "../styles/navbar.scss";
import api from "../api/api";

const Logo = (): JSX.Element => {
	const themeContext: any = useContext(ThemeContext);
	const [searchParams] = useSearchParams();
	const context: any = useContext(LocaleContext);

	const handleClick = async () => {
		try {
			api.ReturnRedirectUrl({"clientID": searchParams.get("clientId")}).then(response => {
				if(response.responseCode == 200) {
					window.location.replace(response.message);
				} else{
					let message = response.details.find((x:any)=> x !==undefined).message;
					throw new Error(context.locale[message]);
				} 
			});
		} catch (error) {
			throw new Error(context.locale.internalServerError);
		}
	};

	return (
		<div className="logo">
			<img src={require(`${process.env.REACT_APP_LOGO_PATH}${themeContext.theme.logo.id}.png`)} 
				alt="Logo" 
				className="navbar-logo"
				onClick={handleClick}
			/>
		</div>
	);
};

export default Logo;