
import { Breadcrumbs, Link } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { useLocation, useSearchParams, useNavigate, redirect } from "react-router-dom";
import { ThemeContext } from "../../context/themeContext";
import { LocaleContext } from "../../context/context";
import { createTheme } from "@mui/material/styles";
import api from "../../api/api";

interface NavbarBreadCrumbProps {
    currentPath?: Array<any>,
    breadcrumbText?: string
}

export const NavbarBreadCrumb = ({currentPath, breadcrumbText} : NavbarBreadCrumbProps) : JSX.Element => {
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);
	const { search } = useLocation();
	const [searchParams] = useSearchParams();
	const context: any = useContext(LocaleContext);
	const navigate = useNavigate();

	const [formError, setFormError] = useState("");
	let breadcrumbAppName = `breadcrumb${breadcrumbText?.replace(/ /g, "")}`; //remove whitespace
	let basePathName = context.locale[breadcrumbAppName] ? context.locale[breadcrumbAppName] : context.locale?.breadcrumbDefault;

	currentPath = typeof currentPath === "undefined" ? [] : currentPath;

	const defaultPaths = [
		{text: basePathName, link: "/"},
		{text: context.locale?.loginTitle, link: "/?clientId=" + searchParams.get("clientId")}
	];

	const [paths, setPaths] = useState<Array<any>>(defaultPaths.concat(currentPath));
	const returnRedirect = (e: any) => {
		try{
			e.preventDefault();
			api.ReturnRedirectUrl({"clientID": searchParams.get("clientId")}).then(response => {
				if(response.responseCode == 200) {
					window.location.replace(response.message);
				} else{
					let message = response.details.find((x:any)=> x !==undefined).message;
					throw new Error(context.locale[message]);
				}
			});
		}catch (err: any) {
			throw new Error(context.locale.internalServerError);
		}
	};

	return (
		<div className='navbar-container'>
			<Breadcrumbs className="navbar-breadcrumbs" separator={""} style={{borderRightColor: theme.color.primary}}>
				{paths ? paths.map((path: any, index: number) => {
					if (index === 0) {
						return ( 
							<Link key={path.text} onClick={returnRedirect}>
								{path.text}
							</Link>
						);
					} else if (index === paths.length - 1) {
						return <p key={path.text}>{path.text}</p>;
					} else {
						//Go to previous route
						return (
							<Link key={path.text} onClick={() =>  navigate(path.link, {replace: true})}>
								{path.text}
							</Link>
						);
					}
				}) : <></>}
			</Breadcrumbs>
		</div>
	);
};