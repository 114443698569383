import Dialog from "@mui/material/Dialog";

import { LocaleContext } from "../context/context";
import { ThemeContext } from "../context/themeContext";
import { createTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";

import { useLocation, Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { getCookies, setCookie } from "../scripts/cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX} from "@fortawesome/pro-solid-svg-icons";
import { DialogPopUp } from "./controls/dialogPopUp";

export interface NewAccountDialogProps {
    open: boolean
}

export const NewAccountDialog = () : JSX.Element => {
	const context: any = useContext(LocaleContext);
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);
	const { search } = useLocation();
	const navigate = useNavigate();

	const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);

	useEffect(() => {
		if(process.env.REACT_APP_ENABLE_NEW_ACCOUNT === "true") {
			setIsFirstLogin(getCookies("isFirstLogin") === "true");
		}
	}, []);

	const handleOnClose = () => {
		setCookie("isFirstLogin", "false"); 
		setIsFirstLogin(false);
	};

	const handleOnPrimaryClose = () => {
		handleOnClose();
		navigate(`/NewAccount${search}`);
	};

	return (
		<DialogPopUp
			open={isFirstLogin}
			onClose={handleOnClose}
			onPrimaryClose={handleOnPrimaryClose}
			title={context.locale.newAccountSetup}
			content={context.locale.newAccountSetupDescription}
			secondaryButtonComponent={<>
				<FontAwesomeIcon icon={faX} size="sm" color={"white"}/>
				{context.locale.dismiss}
			</>}
			primaryButtonComponent={<>
				{/* <Link to={`/NewAccount${search}`} className="form-link primary" style={{color: "white"}}> */}
				<FontAwesomeIcon icon={faCheck} size="sm" color={"white"}/>
				{context.locale.setPassword}
				{/* </Link> */}
			</>}
		/>
	);
};