import { LocaleContext } from "../../context/context";
import { ThemeContext } from "../../context/themeContext";
import { createTheme } from "@mui/material/styles";

import { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faTriangleExclamation} from "@fortawesome/pro-regular-svg-icons";

export interface BannerProps {
    // title: string,
    // desc: string
}

export const Banner = ({} : BannerProps) : JSX.Element => {
	const context: any = useContext(LocaleContext);
	const [isDismiss, setIsDismiss] = useState<boolean>(true);
	let alertTitle = `alertTitle.${process.env.REACT_APP_API_REGION}`;
	let alertDesc = `alertDesc.${process.env.REACT_APP_API_REGION}`;

	useEffect(() => {
		if(localStorage[alertTitle] !== context.locale[alertTitle] || localStorage[alertTitle] !== context.locale[alertTitle]) {
			//Clear cache
			localStorage[alertTitle] = "";
			localStorage[alertDesc] = "";

			if(context.locale[alertTitle] !== "" && context.locale[alertDesc] !== "") {
				setIsDismiss(false);
			}
		}
	}, []);

	const dismissBanner = () => {
		localStorage[alertTitle] = context.locale[alertTitle];
		localStorage[alertDesc] = context.locale[alertDesc];

		setIsDismiss(true);
	};

	return (
		<>
			{!isDismiss && 
            <Box className="banner-container">
            	<Box className="banner">
            		<Box className="banner-title">
            			<FontAwesomeIcon icon={faTriangleExclamation} size="lg"/>
            			{context.locale[alertTitle]}
            		</Box>
            		<Box className="banner-description">
            			{context.locale[alertDesc]}
            		</Box>
            		<FontAwesomeIcon className="banner-button" icon={faXmark} size="sm" onClick={dismissBanner}/>
            	</Box>
            </Box>
			}
		</>
	);
};