import Dialog from "@mui/material/Dialog";

import { LocaleContext } from "../../context/context";
import { ThemeContext } from "../../context/themeContext";
import { createTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";

import { useLocation, Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { getCookies, setCookie } from "../../scripts/cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX} from "@fortawesome/pro-solid-svg-icons";

export interface DialogPopUpProps {
    open: boolean,
    onClose: any,
    onPrimaryClose?: any,
    title: any,
    content: any,
    primaryButtonComponent: any,
    secondaryButtonComponent: any
}

export const DialogPopUp = ({open, onClose, onPrimaryClose, title, content, primaryButtonComponent, secondaryButtonComponent} : DialogPopUpProps) : JSX.Element => {
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);

	return (
		<div>
			<Dialog
				open={open}
				// onClose={handleOnClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{ sx: { borderRadius: "0px" } }}
			>
				<div className="dialog-header" id="alert-dialog-title" style={{backgroundImage: `linear-gradient(130deg, ${theme.color.mftPrimary} 0%, ${theme.color.mftPrimary} 50%, ${theme.color.mftSecondary} 0%)`}}>
					<p className="dialog-title">{title}</p>
					<p className="dialog-close" style={{color: "white"}} onClick={onClose}>{"×"}</p>
				</div>
				<div className="dialog-main">
					<div className="dialog-content" id="alert-dialog-description">
						{content}
					</div>
				</div>
				<div className="dialog-footer">
					<Box onClick={onClose} className="form-box btn" style={{background: theme.color.mftPrimary, color: "white"}}>
						{secondaryButtonComponent}
					</Box>
					<Box className="form-box btn" onClick={onPrimaryClose ? onPrimaryClose : onClose} style={{background: theme.color.primary, color: "white"}}>
						{primaryButtonComponent}
					</Box>
				</div>
			</Dialog>
		</div>
	);
};