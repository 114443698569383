import React, { useState, useContext, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { ThemeContext } from "../context/themeContext";
import { LocaleContext } from "../context/context";
import Box from "@mui/material/Box";
import Logo from "./Logo";
import Button from "@mui/material/Button";
import "../styles/footer.scss";

const Footer = (props: any) :JSX.Element => {
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);
	const context: any = useContext(LocaleContext);

	return(
		<Box className="footer-container">
			<Box className='footer'>
				<Logo/>
				<Box className='footer-social'>
					<Box className='footer-btn-container'>
						<Box className='footer-btns'>
							<Box>
								<Box className="footer-text">{context.locale?.followUs}</Box>
								<Box className='social-btn-container'>
									{theme.footer?.footerSocialMedia?.map((socialMedia:any, i:any) => {
										return <a key={i} target="_blank" rel="noreferrer" href={socialMedia.link} className='social-btn'><img src={require(`${process.env.REACT_APP_SOCIAL_MEDIA_PATH}${socialMedia.file}`)} alt={socialMedia.alt}/></a>;
									})}
								</Box>
							</Box>
						</Box>
						<Box className='footer-btns'>
							<Box>
								{theme.footer?.footerAppStore && <Box className="footer-text">{context.locale?.getTheApp}</Box>}
								<Box className='social-btn-container'>
									{theme.footer?.footerAppStore?.map((appStore:any, i:any) => {
										return <Button key={i} href={appStore.link} className={`download-btn ${appStore.class}`}><img src={require(`${process.env.REACT_APP_STORE_PATH}${appStore.file}`)}/></Button>;
									})}
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className='copyright'>
				<Box className='copyright-container'>
					<Box>{context.locale?.copyright} &copy; {new Date().getFullYear()} {window.Theme.setting.name}</Box>
					<Box className='footer-link left' id="footerLeft">
						{theme.footer?.footerLinks?.map((link:any, i:any) => {
							return <a key={i} target="_blank" rel="noreferrer" href={link.value}>{context.locale ? context.locale[link.key] : ""}</a>;
						})}
					</Box>
				</Box>
			</Box>
		</Box>);
};

export default Footer;